import { useMedicationSearch } from '../hooks/useMedicationSearch';
import { VStack, Text, Box, Pressable, Button, HStack } from 'native-base';
import React from 'react';
import { ActivityIndicator } from 'react-native';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons/faExclamationCircle';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { useQuickStartService } from '../../Common/hooks/useQuickStartService';
import { faPrescriptionBottle } from '@fortawesome/pro-regular-svg-icons/faPrescriptionBottle';
import { faHandPointer } from '@fortawesome/pro-regular-svg-icons/faHandPointer';
import { Icon } from '../../Common/components/Icon';
import { useAnalytics } from '../../Common/hooks/useAnalytics';
import { Input } from './Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type MedicationSearchProps = {
  onSelectMedication: (medicationName: string | undefined | null) => void;
  loading: boolean;
  value: string;
};

export function FormFieldTextMedicationSearch({
  onSelectMedication,
  loading,
  value,
}: MedicationSearchProps) {
  const {
    setSearchTerm,
    searchTerm,
    isDoxy,
    searching,
    isAntibiotics,
    isPrep,
    resetSearchTerm,
    results,
    noResults,
    isWeightLoss,
    setSelectedMedication,
  } = useMedicationSearch(onSelectMedication);

  const { startService, pricingForService } = useQuickStartService();

  const { trackEvent } = useAnalytics();

  const changeToPrep = () => {
    trackEvent('converted_med_search', { to: 'prep' });
    startService('prep');
  };

  const changeToTelehealth = () => {
    trackEvent('converted_med_search', { to: 'consult' });
    startService('consultation');
  };

  const changeToDoxyPEP = () => {
    trackEvent('converted_med_search', { to: 'doxypep' });
    startService('consultation', 'consultation', {
      complaint: 'DoxyPEP',
      prescriptions: 'Yes',
      schedule8: 'Yes',
      warning: 'No',
    });
  };

  return (
    <Box>
      <Input
        mb={2}
        value={value ?? searchTerm}
        size="lg"
        bg={'white'}
        fontSize={'sm'}
        editable={!loading}
        type={'text'}
        onFocus={resetSearchTerm}
        placeholder={'Type the name as it appears on the label'}
        leftElement={
          <Box px={2}>
            <Icon icon={faSearch} />
          </Box>
        }
        placeholderTextColor={'gray.500'}
        rightElement={
          <Box w={6} px={2}>
            {searching && <ActivityIndicator size={'small'} color={'black'} />}
          </Box>
        }
        onChangeText={(text) => setSearchTerm(text)}
      />

      <Text fontSize={'xs'} color={'gray.500'} flexDir={'row'} mb={2}>
        If you have not been prescribed the medication before OR require more than 1 prescription{' '}
        <Pressable onPress={changeToTelehealth}>
          <Text underline={true}>request a telehealth consultation</Text>
        </Pressable>
      </Text>

      {noResults && (
        <VStack space={2} bg={'yellow.200'} p={4} borderRadius={'md'}>
          <Text fontWeight={600} fontSize={'md'}>
            <Icon icon={faExclamationCircle} /> Couldn't find the medication
          </Text>

          <Text>
            Sorry, we could not find the medication. Either try searching differently OR it may be a
            medication we can only prescribe with a doctor's consultation.
          </Text>

          <Button onPress={changeToTelehealth}>Request Doctors Consultation &gt;</Button>
        </VStack>
      )}

      {isAntibiotics && (
        <VStack space={2} bg={'yellow.200'} p={4} borderRadius={'md'}>
          <Text fontWeight={600} fontSize={'md'}>
            <Icon icon={faExclamationCircle} /> Looking for antibiotics?
          </Text>

          <Text>It looks like you are trying to request a prescription for antibiotics.</Text>

          <Text>
            All prescriptions for antibiotics must have a consultation with Dr Cris to ensure
            suitability. This is both for your safety and to help control antibiotics resistance.
          </Text>

          <Button onPress={changeToTelehealth}>Request Doctor Consultation $59 &gt;</Button>
        </VStack>
      )}

      {isWeightLoss && (
        <VStack space={2} bg={'yellow.200'} p={4} borderRadius={'md'}>
          <Text fontWeight={600} fontSize={'md'}>
            <Icon icon={faExclamationCircle} /> Looking for injectable weight loss
            medications?
          </Text>

          <Text>
            It looks like you are trying to request a prescription for injectable weight loss
            medication.
          </Text>

          <Text>
            All prescriptions for new patients looking for weight loss medication must have a
            consultation with Dr Cris. This is for your safety.
          </Text>

          <Button onPress={changeToTelehealth}>Request Doctor Consultation $59 &gt;</Button>
        </VStack>
      )}

      {isDoxy && (
        <VStack space={2} bg={'yellow.200'} p={4} borderRadius={'md'}>
          <Text fontWeight={600} fontSize={'md'}>
            <Icon icon={faExclamationCircle} /> Looking for DoxyPEP or Doxycycline?
          </Text>

          <Text>
            It looks like you are trying to request a prescription for DoxyPEP or Doxycycline.
          </Text>

          <Text>
            New patients requesting DoxyPEP or Doxycycline will need to perform a DoxyPEP
            consultation first with Dr Cris. This consultation will include a discussion around the
            usage of DoxyPEP and eScript if eligible.
          </Text>

          <Button onPress={changeToDoxyPEP}>Request DoxyPEP Consultation $59 &gt;</Button>
        </VStack>
      )}

      {isPrep && (
        <VStack space={2} bg={'yellow.200'} p={4} borderRadius={'md'}>
          <Text fontWeight={600} fontSize={'md'}>
            <Icon icon={faExclamationCircle} /> Needing PrEP?
          </Text>
          <Text>
            It looks like you are trying to request a prescription for PrEP. PrEP is a different
            request type as in order to safely prescribe it we need you to be tested for:
          </Text>

          <Text>- HIV status (Recent test in last 4 weeks required by the PBS)</Text>

          <Text>- Kidney function (PrEP can sometimes affect kidney function)</Text>

          <Text>
            We offer our PrEP service which includes the required testing referral, doctor
            consultation, STI testing referral, and script for {pricingForService('prep')}.
          </Text>

          <Text>
            <Text fontWeight={600}>Run out/Need it urgently?</Text> Don't worry even if you have run
            out we can arrange an emergency script so you can continue PrEP while we get these tests
            underway. Request the PrEP service & indicate you have run out on the form. After
            requesting we will contact you.
          </Text>

          <Text>
            <Text fontWeight={600}>I already have been tested</Text> After requesting PrEP you can
            email your results to us.
          </Text>

          <Button onPress={changeToPrep}>Request PrEP &gt;</Button>
        </VStack>
      )}

      <VStack space={2}>
        {!isPrep &&
          !isDoxy &&
          !isAntibiotics &&
          results?.map((result) => (
            <Pressable
              bg={'white'}
              p={4}
              borderRadius={'md'}
              key={result.name}
              onPress={() => setSelectedMedication(result)}
            >
              <HStack space={4} alignItems={'center'}>
                <VStack space={0.5} flex={1}>
                  <Text fontWeight={600}>
                    <Icon icon={faPrescriptionBottle} /> {result?.name}
                  </Text>
                  {result?.genericName && (
                    <Text color={'gray.500'}>Generic: {result.genericName}</Text>
                  )}
                </VStack>

                <Icon color={'black'} icon={faHandPointer} size={'md'} />
              </HStack>
            </Pressable>
          ))}
      </VStack>
    </Box>
  );
}
