import { isDoctorPortal } from '../isDoctorPortal';
import { brandForDomain } from '../platform/brand';
import Constants from 'expo-constants';

export const getBrandAuthConfig = () => {
  const brand = brandForDomain();

  if (isDoctorPortal()) {
    const config = Constants.expoConfig?.extra?.portal_auth0_web;

    return config ?? {};
  }

  if (brand === 'heyfella') {
    return {
      domain: 'auth.heyfella.com.au',
      audience: 'https://api.heyfella.com.au',
      clientID: 'oTYBMaYuf6UzVRb6H7TsRWcygV5kSOvJ',
    };
  }

  if (brand === 'leafdoctors') {
    return {
      domain: 'auth.leafdoctors.com.au',
      audience: 'https://api.leafdoctors.com.au',
      clientID: 'lHESycyUWH0rWArybLI9rdmMhS1Xsw5R',
    };
  }

  return {
    domain: 'auth.onlinedoctor.clinic',
    audience: 'https://api.onlinedoctor.clinic',
    clientID: '9h6TsR4fVfQ4cRtk8n1a6NncFf66u3GX',
  };
};
