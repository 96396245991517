import React from 'react';
import { Divider, HStack, Text, VStack } from 'native-base';
import { useIsMobile } from '../Common/hooks/useIsMobile';
import { Platform, View } from 'react-native';
import DeviceInfo from 'react-native-device-info';
import { SVGImage } from '../Common/components/SvgImage';
import { Link } from '../Common/components/Navigation';

const LeafDoctorLogo = require('../Assets/LeafDoctors/LD_Logo_Lime.svg');

export function FooterLeafDoctors() {
  const { isMobile } = useIsMobile();

  return (
    <View
      style={{
        backgroundColor: '#123b36',
        marginTop: 20,
        paddingBottom: 40,
      }}
    >
      <View
        style={{
          maxWidth: 1100,
          marginLeft: 'auto',
          marginRight: 'auto',
          width: '100%',
        }}
      >
        <View
          style={{
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <VStack pl={2} pr={2} space={4} pt={8} alignItems={'flex-start'}>
            <SVGImage
              svg={LeafDoctorLogo}
              width={isMobile ? 180 : 220}
              height={isMobile ? 20 : 25}
            />
            <Text color={'white'} fontSize={'lg'}>
              Leaf Doctors provide access to plant based medicine online 7 days a week.
            </Text>

            <Text fontWeight={600} color={'white'} fontSize={'md'}>
              Need Help?
            </Text>

            <Text color={'white'}>Call us on 1300 159 410 or message via the patient portal</Text>

            <Divider />

            <HStack space={4}>
              <Link to={'https://leafdoctors.com.au/legal-privacy-policy'} target={'_blank'}>
                <Text underline={true} fontSize={'sm'} color={'white'}>
                  Privacy Policy
                </Text>
              </Link>

              <Link to={'https://leafdoctors.com.au/shipping-returns-policy'} target={'_blank'}>
                <Text underline={true} fontSize={'sm'} color={'white'}>
                  Shipping & Returns Policy
                </Text>
              </Link>

              <Link to={'https://leafdoctors.com.au/cancellation-no-show-policy'} target={'_blank'}>
                <Text underline={true} fontSize={'sm'} color={'white'}>
                  Cancellation & No Show Policy
                </Text>
              </Link>

              <Link to={'https://leafdoctors.com.au/about-us'} target={'_blank'}>
                <Text underline={true} fontSize={'sm'} color={'white'}>
                  About Us
                </Text>
              </Link>
            </HStack>

            <Text fontSize={'sm'} color={'#d9d1bf'}>
              © 2024 Leaf Doctors, All Rights reserved. 247 King St, Newtown NSW 2042 | Version{' '}
              {Platform.OS === 'web' ? __VERSION__ : DeviceInfo.getVersion()}
            </Text>

            <Text fontSize={'sm'} color={'#d9d1bf'}>
              Disclaimer: Leaf Doctors is not promoting the use of medical plant alternatives.
              Medical plant alternatives affect every person differently, due to factors such as
              size, weight, health, dosage, tolerance and age. Medical plant alternatives might not
              work for you, and you might experience side-effects. For medical advice, please refer
              to your doctor. Medical plant alternatives in Australia are regulated by the
              Therapeutic Goods Administration and details about plant medicine as a scheduled drug
              can be found on their website.
            </Text>
          </VStack>
        </View>
      </View>
    </View>
  );
}
