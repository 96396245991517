import { Platform } from 'react-native';
import * as Sentry from '@sentry/react';
import { useBrandName } from '../../hooks/useBrand';
import { isDoctorPortal } from '../isDoctorPortal';
import { AwsRum } from 'aws-rum-web';
import { brandForDomain } from '../platform/brand';
import LogRocket from 'logrocket';

export const initScripts = () => {
  if (Platform.OS !== 'web') {
    return;
  }

  const loadScripts = document?.location?.hostname?.indexOf('localhost') < 0;
  if (!loadScripts) {
    return;
  }

  const { gtm, cio } = useBrandName();

  if (!isDoctorPortal()) {
    (function (w, d, s, l, i) {
      w[l as any] = w[l as any] || [];
      (w[l as any] as any).push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != 'dataLayer' ? '&l=' + l : '';
      // @ts-ignore
      j.async = true;
      // @ts-ignore
      j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
      // @ts-ignore
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtm);

    (function () {
      let a, b, c;
      a = function (f: any) {
        return function () {
          // @ts-ignore
          _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
        };
      };
      b = ['load', 'identify', 'sidentify', 'track', 'page'];
      for (c = 0; c < b.length; c++) {
        // @ts-ignore
        _cio[b[c]] = a(b[c]);
      }
      const t = document.createElement('script'),
        s = document.getElementsByTagName('script')[0];
      t.async = true;
      t.setAttribute('data-auto-track-page', 'false');
      t.id = 'cio-tracker';
      t.setAttribute('data-site-id', cio);
      t.src = 'https://assets.customer.io/assets/track.js';
      s.parentNode?.insertBefore(t, s);
    })();
  }

  Sentry.init({
    dsn: 'https://739eef624f03449694da7e4d12b7aea5@o1123776.ingest.sentry.io/4505339320664064',
    integrations: [
      new Sentry.Replay({
        networkDetailAllowUrls: [
          'https://api.heyfella.com.au/graphql',
          'https://api.leafdoctors.com.au/graphql',
          'https://api-portal.onlinedoctor.clinic/graphql',
        ],
        networkCaptureBodies: true,
        maskAllText: false,
      }),
    ],
    enabled: true,
    enableTracing: false,
    tracesSampleRate: 0.1,
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  const brand = brandForDomain();

  if (brand === 'leafdoctors') {
    LogRocket.init('be75az/leaf-doctors-bsvul');
  }

  const config = () => {
    if (brand === 'heyfella') {
      return {
        applicationID: '97cacd5b-babd-49c3-9301-6a6fe57b3633',
        config: {
          sessionSampleRate: 1,
          guestRoleArn:
            'arn:aws:iam::718302092024:role/RUM-Monitor-ap-southeast-2-718302092024-7700049064071-Unauth',
          identityPoolId: 'ap-southeast-2:133d2da5-fc57-43ff-abdc-e624c5f4498e',
          endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
          telemetries: [
            'performance',
            'errors',
            [
              'http',
              {
                addXRayTraceIdHeader: true,
                recordAllRequests: true,
                urlsToInclude: [
                  /^https:\/\/api\.heyfella\.com\.au\/.*/,
                  /^https:\/\/patient\.heyfella\.com\.au\/.*/,

                  /^https:\/\/api-dev\.heyfella\.com\.au\/.*/,
                  /^https:\/\/patient-dev\.heyfella\.com\.au\/.*/,
                ],
              },
            ],
          ],
          allowCookies: true,
          enableXRay: true,
        },
      };
    }

    if (brand === 'leafdoctors') {
      return {
        applicationID: '44af9a9a-144d-42d0-8a78-a3761c21a0db',
        config: {
          sessionSampleRate: 1,
          guestRoleArn:
            'arn:aws:iam::718302092024:role/RUM-Monitor-ap-southeast-2-718302092024-1332744264071-Unauth',
          identityPoolId: 'ap-southeast-2:5ff76657-40b8-43d2-85ac-d0d23a6a6d4a',
          endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
          telemetries: [
            'performance',
            'errors',
            [
              'http',
              {
                addXRayTraceIdHeader: true,
                recordAllRequests: true,
                urlsToInclude: [
                  /^https:\/\/api\.leafdoctors\.com\.au\/.*/,
                  /^https:\/\/patient\.leafdoctors\.com\.au\/.*/,

                  /^https:\/\/api-dev\.leafdoctors\.com\.au\/.*/,
                  /^https:\/\/patient-dev\.leafdoctors\.com\.au\/.*/,
                ],
              },
            ],
          ],
          allowCookies: true,
          enableXRay: true,
        },
      };
    }

    if (isDoctorPortal()) {
      return {
        applicationID: '4d22e6f1-4ca4-432f-a5e9-b3bc45c878a6',
        config: {
          sessionSampleRate: 1,
          guestRoleArn:
            'arn:aws:iam::718302092024:role/RUM-Monitor-ap-southeast-2-718302092024-5734827534071-Unauth',
          identityPoolId: 'ap-southeast-2:36efe48d-7ad5-4136-9761-e819a9b47d78',
          endpoint: 'https://dataplane.rum.ap-southeast-2.amazonaws.com',
          telemetries: [
            'performance',
            'errors',
            [
              'http',
              {
                addXRayTraceIdHeader: true,
                recordAllRequests: true,
                urlsToInclude: [
                  /^https:\/\/api-portal-dev\.onlinedoctor\.clinic\/.*/,
                  /^https:\/\/dev-portal\.onlinedoctor\.clinic\/.*/,

                  /^https:\/\/portal\.onlinedoctor\.clinic\/.*/,
                  /^https:\/\/api-portal\.onlinedoctor\.clinic\/.*/,
                ],
              },
            ],
          ],
          allowCookies: true,
          enableXRay: true,
        },
      };
    }

    return null;
  };

  const rumConfig = config();

  if (Platform.OS === 'web' && rumConfig) {
    (window as any).awsRum = new AwsRum(
      rumConfig.applicationID,
      // @ts-ignore
      Platform.OS === 'web' ? __VERSION__ : DeviceInfo.getVersion(),
      'ap-southeast-2',
      rumConfig.config,
    );
  }
};
